/* @flow */

import * as React from 'react';
import {SubmissionError} from 'redux-form';
import {Helmet} from 'react-helmet';
import {formEncodedApi} from 'nutshell-core/api';
import {getStandardTimezoneOffset} from 'nutshell-core/date-time/get-standard-timezone-offset';
import {
    validationErrorCheck,
    ValidationError,
} from 'nutshell-core/validation/validation-error-check';
import {makeErrorObject} from 'nutshell-core/validation/make-error-object';
import {Routing} from 'nutshell-core/routing';

import {
    getMicrosoftOauthUrl,
    trackGoogleLoginError,
    isIOSSignup,
    isMobileAuth,
    buildLoginData,
    getMobileUdid,
    loginWithAuthToken,
} from '../utils';

import {LoginForm} from './login-form';

export type LoginVariant = 'sign-in' | 'password' | 'choose-method';

export function LoginPage() {
    const [googleErrorMessage, setGoogleErrorMessage] = React.useState(null);
    const [microsoftErrorMessage, setMicrosoftErrorMessage] = React.useState(null);
    const [ssoErrorMessage, setSsoErrorMessage] = React.useState(null);
    const [variant, setVariant] = React.useState<LoginVariant>('sign-in');
    const [ssoRedirectUrl, setSsoRedirectUrl] = React.useState<string | null>(null);
    const urlParamString = window.location.search.slice(1); // Remove "?"
    const urlParams = Routing.deparam(urlParamString);

    // To capture error messages from SSO, we take it from the URL as there is
    // no waiting request after user is redirected from SSO with an error.
    React.useEffect(() => {
        if (urlParams.sso_error || urlParams.error_description) {
            setSsoErrorMessage('Invalid SSO login attempt. Please try again or contact support.');
            const newUrl = new URL(`${window.location.origin}/auth`);
            window.history.replaceState({}, '', newUrl);
        }
    }, [urlParams.sso_error, urlParams.error_description]);

    const microsoftOauthUrl = getMicrosoftOauthUrl();

    function handleContinue(formData) {
        return new Promise((resolve, reject) => {
            formEncodedApi
                .post('/auth/on-continue', formData, {redirect: 'follow'})
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.success) {
                        if (data.redirectUrl && data.canUsePasswordAndSso) {
                            setVariant('choose-method');
                            setSsoRedirectUrl(data.redirectUrl);
                            resolve();
                        } else if (data.redirectUrl && !data.canUsePasswordAndSso) {
                            window.location.href = data.redirectUrl;
                            resolve();
                        } else {
                            setVariant('password');
                            resolve();
                        }
                    }
                })
                .catch((err) => {
                    if (err.httpResponse.status === 400) {
                        reject(
                            new SubmissionError({
                                _error: 'We couldn’t find a Nutshell account for that email address.',
                            })
                        );
                    } else {
                        reject(
                            new SubmissionError({
                                _error: 'Error continuing with login, please try again or contact support.',
                            })
                        );
                    }
                });
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Nutshell | Log in to Nutshell</title>
            </Helmet>
            <LoginForm
                initialValues={{
                    via: 'database',
                    timezone_offset: (getStandardTimezoneOffset() / 60) * -1,
                    remember_me: true,
                    username: urlParams.email,
                    // Encompasses both CSRF and expired password reset token,
                    // we show the same generic error message
                    invalidToken: Boolean(urlParams.invalidCsrf) || Boolean(urlParams.invalidToken),
                    sessionTimeout: Boolean(urlParams.sessionTimeout),
                }}
                onContinue={handleContinue}
                ssoErrorMessage={ssoErrorMessage}
                onEmailLogin={handleEmailLogin}
                variant={variant}
                ssoRedirectUrl={ssoRedirectUrl}
                setVariant={setVariant}
                googleErrorMessage={googleErrorMessage}
                microsoftErrorMessage={microsoftErrorMessage}
                onGoogleIdentityFailure={() => {
                    trackGoogleLoginError();

                    this.setState({
                        googleErrorMessage: 'Unknown error connecting to Google. Please try again.',
                    });
                }}
                onGoogleSubmit={(token: string) => {
                    return handleGoogleLogin(token).catch((err: Error) => {
                        const errorMessage = getErrorMessage(err);
                        setGoogleErrorMessage(errorMessage);
                    });
                }}
                onMicrosoftIdentityFailure={(error) => {
                    setMicrosoftErrorMessage(
                        error && error.message
                            ? error.message
                            : 'Unknown error connecting to Microsoft. Please try again.'
                    );
                }}
                onMicrosoftSubmit={(oauthResponse) => {
                    return new Promise((resolve) => {
                        return handleMicrosoftLogin(oauthResponse).catch((error: Error) => {
                            const errorMessage = getErrorMessage(error);
                            setMicrosoftErrorMessage(errorMessage);
                            resolve();
                        });
                    });
                }}
                microsoftOauthUrl={microsoftOauthUrl}
                showSignup={!isIOSSignup()}
                isMobile={isMobileAuth()}
            />
        </React.Fragment>
    );
}

function getErrorMessage(error: Error): string {
    if (error instanceof ValidationError && error.validationResult) {
        const errorObject = makeErrorObject(error);
        if (errorObject && errorObject._error && Array.isArray(errorObject._error)) {
            return errorObject._error[0];
        } else {
            if (typeof window.trackJs !== 'undefined') {
                window.trackJs.track('unknown google auth validation error');
                window.trackJs.track(errorObject);
            }

            return 'Not able to log into Nutshell using SSO at this time.';
        }
    } else {
        if (typeof window.trackJs !== 'undefined') {
            window.trackJs.track('unknown google auth error');
            window.trackJs.track(error);
        }

        return 'Not able to log into Nutshell using SSO at this time.';
    }
}

function handleMicrosoftLogin(token: string): Promise<*> {
    return attemptNutshellLogin({
        password: token,
        strategy: 'o365',
        via: 'database',
        timezone_offset: (getStandardTimezoneOffset() / 60) * -1,
        remember_me: true,
    });
}

function handleGoogleLogin(token: string): Promise<*> {
    return attemptNutshellLogin({
        password: token,
        strategy: 'google',
        via: 'database',
        timezone_offset: (getStandardTimezoneOffset() / 60) * -1,
        remember_me: true,
    });
}

function attemptLogin(formData, resolve, reject) {
    attemptNutshellLogin(formData)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            if (err instanceof ValidationError && err.validationResult) {
                reject(new SubmissionError(makeErrorObject(err)));
            }

            if (typeof window.trackJs !== 'undefined') {
                window.trackJs.track(`Could not log in: ${JSON.stringify(err, null, 2)}`);
            }

            reject(
                new SubmissionError({
                    _error: 'There was an error logging in.  Please refresh the page and try again, or contact support.',
                })
            );
        });
}

function handleEmailLogin(formData): Promise<*> {
    if (formData.mfaCode) {
        return new Promise((resolve, reject) => {
            return formEncodedApi
                .post('/auth/mfa', {code: formData.mfaCode}, {redirect: 'follow'})
                .then((res) => {
                    if (res && res.redirected) {
                        window.location.href = res.url;
                    }

                    return res;
                })
                .then(validationErrorCheck)
                .then((res) => res.json())
                .catch((err) => {
                    if (err instanceof ValidationError && err.validationResult) {
                        reject(new SubmissionError(makeErrorObject(err)));
                    }

                    reject(
                        new SubmissionError({
                            _error: 'There was an error logging in.  Please refresh the page and try again, or contact support.',
                        })
                    );
                });
        });
    }

    return new Promise((resolve, reject) => {
        if (typeof window.grecaptcha === 'undefined') {
            if (typeof window.trackJs !== 'undefined') {
                window.trackJs.track(`LoginPage: Recaptcha code never loaded.`);
            }

            // No recaptcha included
            attemptLogin({...formData, recaptchaToken: 'SCRIPT_BLOCKED'}, resolve, reject);
        } else {
            // eslint-disable-next-line no-undef
            grecaptcha.ready(() => {
                // eslint-disable-next-line no-undef
                grecaptcha
                    .execute(window.RecaptchaConfig.publicApiKey, {action: 'login'})
                    .then((token) => {
                        attemptLogin(
                            {
                                ...formData,
                                recaptchaToken: token,
                            },
                            resolve,
                            reject
                        );
                    })
                    .catch(() => {
                        if (typeof window.trackJs !== 'undefined') {
                            window.trackJs.track(
                                `LoginPage: Recaptcha code could not produce recaptcha token.`
                            );
                        }

                        attemptLogin(
                            {...formData, recaptchaToken: 'COULD_NOT_PRODUCE_TOKEN'},
                            resolve,
                            reject
                        );
                    });
            });
        }
    });
}

type FormData = {|
    username?: string,
    password: string,
    remember_me: boolean,
    strategy: string,
    timezone_offset: number,
    via: string,
|};

function attemptNutshellLogin(formData: FormData): Promise<*> {
    // We need to remove the `X-Requested-With` header, otherwise the backend
    // treats this as a json request, and won't redirect.
    if (
        formEncodedApi.options.headers &&
        typeof formEncodedApi.options.headers.delete === 'function'
    ) {
        formEncodedApi.options.headers.delete('X-Requested-With');
    }

    const isMobile = isMobileAuth();
    const udid = isMobile ? getMobileUdid() : null;

    const loginData = buildLoginData(formData, isMobile, udid);

    if (isMobile) {
        return formEncodedApi
            .post('/auth', loginData)
            .then(validationErrorCheck)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.authToken) {
                    loginWithAuthToken(formData.username, data.authToken);
                }
            });
    }

    return formEncodedApi
        .post('/auth', formData, {redirect: 'follow'})
        .then((res) => {
            if (res && res.redirected) {
                window.location.href = res.url;
            }

            return res;
        })
        .then(validationErrorCheck)
        .then((res) => res.json());
}
