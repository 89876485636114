/* @flow */

import * as React from 'react';

import {LoadingIcon} from 'shells/icon';
import {colors} from 'shells/colors';
import MicrosoftSquareLogo from 'shells/logos/microsoft-square.svg';

import {openOauthWindow} from '../../../oauth/open-oauth-window';

import './microsoft-oauth-button.css';

type Props = {|
    variant: 'login' | 'signup',
    oauthUrl: ?string,
    onAuthSuccess: (string) => void,
    onAuthFailure: (?Error) => void,
    isSubmittingAuthResult: boolean,
|};

export function MicrosoftOauthButton(props: Props) {
    const copy = `${props.variant === 'login' ? 'Sign in' : 'Continue'} with Microsoft`;
    const loadingCopy = `${props.variant === 'login' ? 'Signing in' : 'Signing up'} with Microsoft`;

    return (
        <button
            type='button'
            styleName='microsoft-oauth-button'
            disabled={props.isSubmittingAuthResult}
            onClick={(event) => {
                event.preventDefault();

                if (!props.oauthUrl) {
                    props.onAuthFailure(new Error('Cant login with Microsoft. Try again later'));

                    return;
                }

                openOauthWindow(
                    props.oauthUrl,
                    copy,
                    {
                        toolbar: 'no',
                        location: 'no',
                        width: 600,
                        height: 850,
                    },
                    true
                )
                    .then((response) => {
                        if (response) {
                            props.onAuthSuccess(response);
                        }
                    })
                    .catch((error) => {
                        props.onAuthFailure && props.onAuthFailure(error);
                    });
            }}
        >
            <MicrosoftSquareLogo />
            {props.isSubmittingAuthResult ? (
                <>
                    <span>{loadingCopy}</span>
                    <LoadingIcon size={29} fill={colors.offWhiteDk} />
                </>
            ) : (
                <span>{copy}</span>
            )}
        </button>
    );
}
