/* @flow */

import {formEncodedApi} from 'nutshell-core/api';
import {Routing} from 'nutshell-core/routing';
import {
    validationErrorCheck,
    ValidationError,
} from 'nutshell-core/validation/validation-error-check';
import type {
    OauthSignupParams,
    EmailSignupParams,
    RequestInviteParams,
    SignupAnalyticsRecordParams,
    WaitForProvisionParams,
} from './types';
import {MICROSOFT_OAUTH_URL_KEY} from './constants';

export const handleEmailSignup = (params: EmailSignupParams): Promise<*> => {
    const sources = getSignupSources(window.location.search);

    return formEncodedApi.post(buildEndpoint('nutshell-signup'), {...params, source: sources});
};

export const handleGoogleToken = (params: OauthSignupParams): Promise<*> => {
    const sources = getSignupSources(window.location.search);

    return formEncodedApi.post(buildEndpoint('nutshell-signup-google'), {
        ...params,
        source: sources,
    });
};

export const handleMicrosoftToken = (params: OauthSignupParams): Promise<*> => {
    const sources = getSignupSources(window.location.search);

    return formEncodedApi.post(buildEndpoint('nutshell-signup-microsoft'), {
        ...params,
        source: sources,
    });
};

// Logs unknown google identity error if one occurs, new GIS onError does not return a message
// see: https://github.com/MomenSherif/react-oauth#googlelogin
export const trackGoogleLoginError = () => {
    if (typeof window.trackJs !== 'undefined') {
        window.trackJs.track('Unknown google identity login error');
    }
};

export const checkProvisionStatus = (
    params: WaitForProvisionParams,
    onSuccess: () => void,
    onError: () => void
): void => {
    formEncodedApi
        .post('/api/v1/public/wait-for-provision', {...params})
        .then((response) => response.json())
        .then(onSuccess)
        .catch(onError);
};

export const verifyMfaCode = (params: {
    userId?: string,
    mfaType: string,
    mfaCode: string,
}): Promise<*> => {
    return formEncodedApi.post('/mfa/verify-mfa-code', {...params});
};

export const regenerateMfa = (params: {
    mfaType: string,
    userData?: string | number,
}): Promise<*> => {
    return formEncodedApi
        .post('/mfa/regenerate-mfa', {...params})
        .then((response) => response.json());
};

export const getBackupCodes = (): Promise<*> => {
    return formEncodedApi.post('/mfa/get-backup-codes').then((response) => response.json());
};

export const mfa = (params: {userData?: string | number}): Promise<*> => {
    return formEncodedApi.post('/auth/mfa/', {...params}).then((response) => response);
};

export const getMfa = (): Promise<*> => {
    return formEncodedApi.post('/mfa/get-mfa', {}).then((response) => response.json());
};

export const getUserMfa = (params: {userId?: string}): Promise<*> => {
    return formEncodedApi
        .post('setup/user/get-user-mfa', {userId: params.userId})
        .then((response) => response.json());
};

export const disableMfa = (params: {userData?: string | number}): Promise<*> => {
    return formEncodedApi
        .post('/mfa/disable-mfa', {...params})
        .then(validationErrorCheck)
        .then((response) => response.json())
        .catch((err) => {
            throw err;
        });
};

export const authWithoutLogin = (password: string, username: string): Promise<*> => {
    return formEncodedApi
        .post('/auth/auth-without-login', {
            username: username,
            password: password,
        })
        .then((response) => response.json())
        .then((response) => {
            // Backend returns an empty array on success, object on failure.
            if (!Array.isArray(response)) {
                throw new ValidationError('wrong password');
            }
        })
        .catch((e) => {
            throw e;
        });
};

export const disableUserMfa = (params: {userId: string}): Promise<*> => {
    return formEncodedApi
        .post('setup/user/disable-user-mfa', {...params})
        .then(validationErrorCheck)
        .then((response) => response.json())
        .catch((err) => {
            throw err;
        });
};

export const signupAnalyticsRecord = (params: SignupAnalyticsRecordParams): Promise<*> => {
    return formEncodedApi.post('/api/v1/public/signup-analytics-record', {...params});
};

export const handleInvitationRequest = (params: RequestInviteParams): Promise<*> => {
    return formEncodedApi.post(buildEndpoint('request-invitation-from-admin'), {...params});
};

export function isDevelopment() {
    return (
        window.location.hostname.includes('kojo.zone') ||
        window.location.hostname.includes('squirrel.house')
    );
}

export function getGoogleAppClientId() {
    return isDevelopment()
        ? '331287150382-agip93mg1tjd1n1d7nc9ca34lth9gamn.apps.googleusercontent.com'
        : '926894378768-fgosc4ft419nbj6keabjg3tamfls8iel.apps.googleusercontent.com';
}

function buildEndpoint(page: string) {
    return isDevelopment()
        ? `/api/v1/public/${page}/1:bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb`
        : `/api/v1/public/${page}/4:fc0ec3f5093b7a03e938db41710cb1cf0d4e888e`;
}

export const isMobileSignup = (): boolean => {
    return isIOSSignup() || isAndroidSignup();
};

export const isIOSSignup = (): boolean => {
    return (
        typeof window.webkit !== 'undefined' &&
        typeof window.webkit.messageHandlers !== 'undefined' &&
        typeof window.webkit.messageHandlers.NutMobile !== 'undefined'
    );
};

export const isAndroidSignup = (): boolean => {
    // $FlowIgnore NutMobile should exist on the android app
    return typeof NutMobile !== 'undefined';
};

export function postIOSMessage(message: Object) {
    window.webkit.messageHandlers.NutMobile.postMessage(message);
}

export function isMobileAuth(): boolean {
    return isIOSSignup() || isAndroidSignup();
}

export function getMobileUdid(): ?string {
    if (isMobileAuth()) {
        // $FlowIgnore NutMobile should exist on the android app
        return NutMobile.getUdid(); // eslint-disable-line no-undef
    }

    return null;
}

export const getSignupSources = (search: string): string[] => {
    const urlParams = Routing.deparam(search, {comma: true});
    let sources = [];

    if (isIOSSignup()) {
        sources.push('iOS Signup');
    } else if (isAndroidSignup()) {
        sources.push('Android Signup');
    } else {
        sources.push('Web Signup');
    }

    if (urlParams.source) {
        sources = sources.concat(urlParams.source);
    }

    return sources;
};

export const login = (username: string, password: string) => {
    if (isIOSSignup()) {
        postIOSMessage({
            method: 'login',
            params: [username, password],
        });
    } else if (isAndroidSignup()) {
        /* eslint-disable no-undef */
        // $FlowIgnore NutMobile should exist on the android app
        NutMobile.login(username, password);
    }
};

export const loginWithAuthToken = (username: ?string, authToken: string) => {
    if (isIOSSignup() && username) {
        postIOSMessage({
            method: 'login',
            params: [username, authToken],
        });
    } else if (isAndroidSignup() && username) {
        /* eslint-disable no-undef */
        // $FlowIgnore NutMobile should exist on the android app
        NutMobile.login(username, authToken);
    }
};

export const buildLoginData = (formData: Object, isMobile: boolean, udid: ?string): Object => {
    return {
        ...formData,
        isMobile,
        udid,
    };
};

export const showLoginForm = () => {
    if (isIOSSignup()) {
        // On iOS, we need to post a message back to native land to open the login
        // screen if we tap the login button
        postIOSMessage({
            method: 'navigateToLogin',
            // We require params on the iOS app. So just send an empty array
            params: [],
        });
    }

    // $FlowIgnore NutMobile should exist on the android app
    if (isAndroidSignup() && typeof NutMobile.showLoginForm !== 'undefined') {
        /* eslint-disable no-undef */
        // $FlowIgnore NutMobile should exist on the android app
        NutMobile.showLoginForm();
    }
};

export const getMicrosoftOauthUrl = (): ?string => {
    const input = document.getElementById(MICROSOFT_OAUTH_URL_KEY);
    if (!input) {
        return null;
    }
    const oauthUrl = input.getAttribute('value');

    if (!oauthUrl) {
        return null;
    }

    return oauthUrl;
};
